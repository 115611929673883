.main {
    padding: 10px 20px 10px;
}

.PlaneListRow td {
    padding: 2px 4px !important;
}

.ant-collapse-header {
  font-weight: bold;
}

.ConfigGrid {
  grid-template-columns:60px auto;
  grid-template-rows: repeat(4,40px);
  display: grid;
}
.ConfigGrid>div {
  margin:auto 4px;
}

.StandartValue {
  color: #80808080;
}

.ActualVersion {
  font-weight: bold;
}

.SkyFighters>div {
  padding: 4px 0;
}